import * as React from 'react';

import { connect } from 'react-redux';
import Footer from '../components/Footer';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Vermiculite from '../components/vermiculite/Vermiculite';
import VermiculiteGrades from '../components/vermiculite/VermiculiteGrades';

const VermiculitePage = () => {
  return (
    <Layout>
      <Seo title="Vermiculite" />
      <Vermiculite />
      <VermiculiteGrades />
      <Footer />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(VermiculitePage);
