import React from 'react';
import grades from '../../images/vermiculite-grades.jpeg';

const VermiculiteGrades = () => {
  return (
    <div className="surface-section px-4 py-8 md:px-6 lg:px-8 overflow-hidden">
      <div className="font-bold text-900 text-3xl mb-5 mt-4 text-center position-relative"
      data-aos="fade-up"
      data-aos-duration="1000">Vermiculite <span className="green-c">Grades</span></div>
      <img src={grades} alt="Image" className="w-full mr-8 mb-5" />
    </div>
  );
};

export default VermiculiteGrades;
