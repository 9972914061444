import React from 'react';
import introImg from '../../images/intro-img.jpeg';
import vermiculite from '../../images/vermiculite.jpg';
import vermiculitebg from '../../images/vermiculite-bg.webp';
import SingleGallerySlide from '../peat-moss/SingleGallerySlide';
import v1 from '../../images/v1.jpeg';
import v2 from '../../images/v2.jpeg';
import v4 from '../../images/v4.jpeg';

const Vermiculite = () => {
  return (
    <div
      className="h-100vh vermiculite-intro-wrapper"
      data-aos="fade-up"
      data-aos-duration="900"
      style={{
        background: `linear-gradient(rgba(255,255,255,0.4), rgba(255,255,255,0.4)), url(${vermiculitebg}) no-repeat`
      }}
    >
      <div
        style={{ height: '200px', background: `url(${introImg}) no-repeat` }}
        className="vermiculite-intro"
      ></div>
      <div className="px-4 py-5 md:px-6 lg:px-8">
        <div
          className="flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative"
          style={{ marginTop: '-2rem', top: '-70px', marginBottom: '-70px' }}
        >
          <div>
            <div
              style={{ width: '140px', height: '140px', borderRadius: '10px' }}
              className="mb-3 surface-card shadow-2 flex align-items-center justify-content-center"
            >
              <img
                src={vermiculite}
                alt="vermiculite"
                className="border-round shadow-3"
                style={{ width: '100%', height: '100%' }}
              />
            </div>

            <div className="mb-3">
              <div className="border-bottom-1 surface-border">
                <span className="block text-3xl font-medium text-900 mb-4">
                  Vermiculite
                </span>
              </div>
            </div>

            <p className="mt-0 mb-3 text-700 text-xl">
              What is <span className="green-c">Vermiculite</span>?
            </p>
            <div className="text-600 font-medium lg-half-width">
              <p className="mb-3">
                Vermiculite is a light, versatile and safe mineral used in the
                horticultural, animal feed, insulation, packing, fireproofing
                and many other industries. Due to its inert properties it allows
                usage across a wide range of global sectors. Vermiculite is a
                hydrated magnesium aluminum silicate mineral which resembles
                mica in appearance and {"it's"} formed by weathering or
                hydrothermal alteration of biotite or phlogopite.
              </p>
              <p>{'It\'s'} mined
                using open cast mining techniques where the ore is screened and
                classified into five different particle sizes namely: Large,
                Medium, Fine, Superfine and Micron. Vermiculite undergoes
                significant expansion when heated. The mineral is expanding into
                worm-like strands. When expanded it drastically increases its
                volume and absorption ability. It is a silica mineral and are
                used in agriculture, horticulture, construction and industrial
                sectors. Vermiculite is also an excellent material for
                fire-proofing and insulation. It is also used for insulation or
                as a moisture-retentive medium for growing plants.</p>

            </div>
            <div className="mt-3 w-100">
              <div className="grid">
                <div className="col-12 lg:col-2 lg:m-5">
                  <div
                    className={'shadow-1 border-round surface-card h-full '}
                    style={{ maxHeight: '100%', maxWidth: '100%', minWidth: '250px', padding: '5px' }}
                  >
                    <div className="h-100 w-100">
                      <img
                        src={v1}
                        alt="peat-moss"
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          minHeight: '100%',
                          minWidth: '100%'
                        }}
                      />
                    </div>
                  </div>
                </div>
                <SingleGallerySlide image={v2} style={{ padding: '5px' }} xtraClasses="lg:ml-5" />
                <SingleGallerySlide image={v4} style={{ padding: '5px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vermiculite;
